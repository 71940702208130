.qrCodeContainer {
    height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.QRItem {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.DetailsContainer {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    color: rgb(3, 92, 205);
}

.ParagraphContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-content: center;
    margin-top: 10%;
    margin-left: 8%;
}
.ParagraphItem {
    margin-left: 5%;
    height: 100%;
    letter-spacing: 0.46px;
}
