.JoinMeetingComponent {
    background: #fff;
    display: flex;
    flex-grow: 1;
}

.JoinInputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-top: 1%;
}

.JoinMessage {
    text-align: center;
}

.InputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1vh;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 5%;
    margin-bottom: 3%;
}

.RoomInput {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}

.InputMessage {
    flex-grow: 1;
}

.MuiOutlinedInput-root {
    color: #235dbc;
}

.JoinButton {
    display: flex;
    width: 100%;
    height: 10vh;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
.JoinButton Button {
    height: 100%;
    width: 100%;
}

.KeyboardContainer,
.Keyboard {
    display: flex;
    flex-grow: 1;
    align-items: stretch;
}
