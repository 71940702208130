.HeaderContainer {
    display: flex;
    flex-direction: row;
    height: 50px;
}
.meetingUrl {
    height: 100%;
    width: 68vw;
    justify-content: flex-start;
}
.meetingUrl h4 {
    text-transform: none;
}
.QrcodeButton {
    width: 11vw;
}
.OpenTopBarButton {
    border: 1px solid rgb(3, 92, 205);
    border-radius: 6px;
    width: 11vw;
}
.CloseButton {
    position: absolute;
    top: 0;
    right: 0;
}
.ReturnButton {
    position: absolute;
    top: 0;
    left: 0;
}
.QrcodeScannerButton {
    width: 11vw;
}
