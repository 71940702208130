.ConnectionContainer {
    background: #ffffff;
    height: 80vh;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
}
.Partners {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    gap: 3%;
}

.TitleContainer h2 {
    color: #035ccd;
}

.FirstLineButton {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
}
.MediumButton {
    height: 100%;
    display: flex;
    width: 100%;
}
.TitleContainer {
    color: #035ccd;
    width: 60vw;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
}
.Paragraph {
    width: 70%;
}
.MarshaButton {
    width: 100%;
}
.TitleButton {
    margin-top: 10%;
    color: #035ccd;
}
