.HomeButtonsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    align-self: center;
    background: #1f1f1f;
    height: 100vh;
    width: 100vw;
}
.LogoContainer {
    align-self: center;
    margin-top: 5%;
}

.logo {
    display: flex;
}
.LanguageSupport {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 2%;
    margin-bottom: 2%;
}
